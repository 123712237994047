/* App.css */
/* General styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  line-height: 24px;
  background-color: #ffffff;
  color: #000;
  margin: 48px auto 0px auto;

  -webkit-font-smoothing: auto;
}

p {
  text-align: left;
  margin: 10px 0px 0px 0px;
}

code {
  color: #f8792a;
  background-color: #fef9f7;
  padding: 2px;
  border-radius: 3px;
}

.new-hero-header {
  font-size: 48px;
  font-weight: 700;
  max-width: 800px;
  margin: 0 auto 24px;
  line-height: 1.1;
  font-weight: 600;
}

.new-hero-paragraph {
  font-size: 20px;
  color: #666;
  max-width: 600px;
  margin: 0 auto 20px;
  text-align: center;
  font-weight: 400;
}

.hero-before-image {
  width: 35%;
  border-radius: 8px 8px 0 0;
  border-top: 3px solid #ddd;
  border-left: 3px solid #ddd;
  border-right: 3px solid #ddd;
  aspect-ratio: 1.6;
  height: auto;
}

.hero-after-image {
  width: 45%;
  position: relative;
  display: flex;
  aspect-ratio: 1.6;
  height: auto;
}

.hero-images-container {
  max-width: 1200px;
  margin: 0 auto 48px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
}

.new-hero-reviews-container {
  padding: 8px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
}

cite {
  font-style: normal;
}

.free-tool-form {
  display: flex;
  gap: 6px;
  align-items: center;
}

.free-tool-form-number-container {
  background-color: #ddedfb;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 4px;
}

.free-tool-form-number {
  font-size: 12px;
  color: #2483e2;
  font-weight: 400;
  width: 20px;
}

.body-real-estate {
  max-width: 1080px;
  margin: 48px auto;
}

.nav-toggle {
  display: none;
}

.nav-links {
  font-size: 16px;
}

h4 {
  color: #878787;
  font-weight: 400;
  margin-bottom: 8px;
}

.nav-links.collapsed {
  display: none;
}

.pricing-modal-button-container {
  display: flex;
  align-items: center;
  align-self: center;
  padding-top: 40px;
  padding-bottom: 10px;
}

/* Hero styles */

.hero-description-and-example-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.hero-description-container {
  display: flex;
  flex-direction: column;

  height: 100%;
  justify-content: center;
}

.hero-example-container {
  width: 40vw;
  display: flex;
  flex-direction: column;
}

.hero-cta-and-reviews-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 12px;
}

.hero-description-benefit-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.hero-description-icon-container {
  background-color: #fef2ea;
  border-radius: 3px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-loader {
  display: flex;
  flex: 1 0 0;
  padding: 4px;
  align-items: center;
  height: 20px;
  white-space: nowrap;
  gap: 4px;
  border-radius: 6px;
  font-weight: 500;
  color: #000;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
    rgba(64, 68, 82, 0.16) 0px 0px 0px 1px;
  transition: background 0.2s ease-out;
}

.image-arrow-container {
  font-size: 32px;
  width: 3%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.image-arrow {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hero-button {
  display: flex;
  flex: 1 0 0;
  padding: 4px;
  align-items: center;
  height: 20px;
  white-space: nowrap;
  gap: 4px;
  border-radius: 6px;
  font-weight: 500;
  color: #000;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
    rgba(64, 68, 82, 0.16) 0px 0px 0px 1px;
  transition: background 0.2s ease-out;
}

.hero-button:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}

.hero-button:active {
  background-color: #eeeeee;
  cursor: pointer;
}

.hero-button.selected {
  box-shadow: rgb(35, 131, 226) 0px 0px 0px 2px,
    rgba(182, 182, 182, 0.25) 0px 8px 12px;
}

.hero-header {
  color: #141719;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 64px */
  width: 50vw;
  margin: 0px 0px 0px 0px;
}

.hero-example-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin-top: 10px;
}

.button {
  /* Styling */
  background-color: #f8792a;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 6%, 0.1),
    0 1px 2px hsla(0, 0%, 6%, 0.1);
  border: none;
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  border-radius: 6px;
  padding: 12px 16px;
}

.button-end-of-page {
  /* Styling */
  background-color: #f8792a;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 6%, 0.1),
    0 1px 2px hsla(0, 0%, 6%, 0.1);
  border: none;
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  border-radius: 6px;
  padding: 12px 16px;
  align-items: center;
}

h3 {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* Pricing page */

.modal-product-tiers-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-product-tier-current {
  display: flex;
  padding: 17px 13px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px;
}

.modal-product-current-tier-banner {
  display: flex;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  flex-shrink: 0;
  margin-top: -25px;
  background-color: white;
  padding: 2px 10px;
  border: 1px solid #f5c6ab;
  color: #f5c6ab;
  border-radius: 20px;
}

.modal-product-current-tier-banner-empty {
  display: flex;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-top: -25px;
  padding: 2px 6px;
}

.modal-product-plan-description {
  display: flex;
  width: 370px;
  padding: 5px 11px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.modal-product-plan-description-text-1 {
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 171.429% */
}

.modal-product-plan-description-text-2-3-container {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.modal-product-plan-description-text-2 {
  color: #000;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
}

.modal-product-plan-description-text-3 {
  color: #878787;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 20px;
  line-height: 104%; /* 171.429% */
}

.modal-product-plan-description-text-4 {
  color: #000;
  font-style: normal;
  display: flex;
  align-items: center;
  font-weight: 400;
  gap: 4px;
  font-size: 18px;
  line-height: 140%;
}

.step-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 980px;
  gap: 20px;
}

.step-container.article {
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 760px;
  gap: 20px;
}

.button:hover {
  /* Styling on hover */
  cursor: pointer;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 6%, 0.1),
    0 1px 2px hsla(0, 0%, 6%, 0.1);
  transition-duration: 0.2s;
  background-color: #f16209;
}

.button-end-of-page:hover {
  cursor: pointer;
  transition-duration: 0.05s;
  background-color: #e98145;
}

.button-container {
  gap: 12px;
  display: flex;
  padding-right: 12px;
}

/* Free tool styles */

.free-tool-value-prop {
  background-color: #fff;
  color: #878787;
  font-weight: 500;
  font-size: 16px;
  padding: 4px 14px 4px 8px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  gap: 6px;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset,
    rgba(15, 15, 15, 0.1) 0px 1px 2px;
}

.section-first-text.free-tool-value-propcontainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.free-tool-input {
  background-color: #fff;
  width: 100%;
  font-size: 16px;
  text-align: left;
  height: 26px;
  padding-left: 10px;
  border-radius: 5px;
  border-color: #878787;
  margin: 0px;
  padding-right: 0px;
}

.free-tool-label {
  margin: 0px;
}

.free-tool-button {
  display: flex;
  width: 105%;
  height: 36px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border: none;
  border-radius: 5px;
  background-color: rgb(35, 131, 226);
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset,
    rgba(15, 15, 15, 0.1) 0px 1px 2px;
}

.free-tool-button:hover {
  background-color: rgb(0, 119, 212);
  cursor: pointer;
  transition-duration: 0.3s;
}

.free-tool-button.disabled {
  background-color: #aacaef !important;
}

.free-tool-result-container {
  background-color: #fff1e9;
  padding: 0px 12px 12px 12px;
  border-radius: 12px;
  border: 1px solid rgb(224, 224, 224);
  box-shadow: rgba(15, 15, 15, 0.1) 0px 3px 6px 0px,
    rgba(15, 15, 15, 0.2) 0px 9px 24px 0px;
}

.article-title-on-blog a {
  color: #000; /* Default color */
  cursor: pointer;
  transition: color 0.2s ease-in-out; /* Transition effect applied directly to the link */
  padding: 0px;
}

a.article-link {
  font-weight: 500;
  padding: 0 !important; /* Override padding */
  background-color: none;
  transition: background 0.2s ease-in-out;
  transition: border-bottom 0.1s ease-in;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom: 1px solid #ddd;
}

a.article-link:hover {
  color: rgb(26, 136, 248) !important;
  background-color: #eef6fd;
  border-bottom: 2px solid rgb(26, 136, 248);
}

a.article-link:active {
  background-color: #ddedfb;
}

a.article-link.footer {
  font-weight: 400;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

a.article-link.footer:hover {
  font-weight: 400;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.article-title-on-blog a:hover {
  color: #878787; /* Color changes on hover */
  cursor: pointer;
  padding: 0px;
}

.article-text {
  font-weight: 400;
  font-size: 17px;
  letter-spacing: -0.22px;
}

.article-titleh1 {
  font-size: 36px;
  margin-top: 0px;
  margin-bottom: 12px;
  font-weight: 500;
  line-height: 112%;
  color: #000;
}

.article-subtitle {
  font-size: 24px;
  line-height: 110%;
  font-weight: 500;
  color: #878787;
  margin-top: 20px;
  margin-bottom: 12px;
}

.article-subtitleh2 {
  font-size: 24px;
  line-height: 120%;
  font-weight: 500;
  color: #000;
  margin-top: 10px;
  margin-bottom: 12px;
}

.article-subtitle-h4 {
  color: #000;
  font-weight: 600;
}

strong {
  font-weight: 600;
}

.button-top-of-page {
  /* Styling */
  display: flex;
  background-color: #f8792a;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 6%, 0.1),
    0 1px 2px hsla(0, 0%, 6%, 0.1);
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border-radius: 6px;
  padding: 8px 16px;
  align-items: center;
  gap: 6px;
}

.button-text {
  line-height: 16px;
}

.button-top-of-page:hover {
  cursor: pointer;
  transition-duration: 0.05s;
  background-color: #f16209;
}

.button-small {
  display: flex;
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border-radius: 6px;
  padding: 6px 10px;
  box-shadow: 0px 1px 8px 0px #0000003a;
  gap: 6px;
}

.button-small-header {
  background-color: #f8792a;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 6%, 0.1),
    0 1px 2px hsla(0, 0%, 6%, 0.1);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  padding: 6px 10px;
  border: none;
}

.mls {
  height: 125px;
}

.author-block {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #878787;
  padding-top: 12px;
  gap: 8px;
  align-items: center;
}

.author-block-name-position {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.author-block-name {
  line-height: 100%;
  color: #000;
}

.author-block-name-position {
  line-height: 100%;
}

.button-small-secondary {
  background-color: transparent;
  border: 1px solid transparent;
  color: black;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  padding: 6px 8px;
  display: inline-flex;
  gap: 4px;
  justify-content: center;
  line-height: 100%;
  display: flex;
  align-items: center;
}

.button-small:hover {
  /* Styling on hover */
  cursor: pointer;
  border: 1px solid #000;
  background-color: #000;
}

.button-small-header:hover {
  /* Styling on hover */
  cursor: pointer;
  transition-duration: 0.05s;
  background-color: #f16209;
}

.button-small-secondary:hover {
  /* Styling on hover */
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}

.button-navbar-divider {
  display: block;
  height: 32px;
  margin-left: 1px;
  width: 1px;
  border-right: 1px solid #e0e0e0;
  padding-left: 5px;
  margin-right: 5px;
}

.flying-menu {
  position: fixed; /* Or absolute, depending on your layout */
  top: 10; /* Adjust as needed */
  left: 0; /* Adjust as needed */
  padding: 8px;
  width: 100%; /* Or adjust to your preference */
  background-color: white; /* Or any color of your choice */
  z-index: 10;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #f7f7f7;
  /* Add more styling as needed */
}

.picture img {
  width: 100%; /* Ensure the SVG takes the full width of its container */
  height: auto; /* Maintain aspect ratio */
}

.text-highlight {
  background: linear-gradient(
    90deg,
    rgba(248, 148, 85, 0) 0,
    rgba(248, 148, 85, 0.75) 5%,
    rgba(248, 148, 85, 0.25) 95%,
    rgba(248, 148, 85, 0) 100%
  );
  border-radius: 3px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  display: inline;
}

.header-picture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-picture img {
  width: 75%; /* Ensure the SVG takes the full width of its container */
  height: auto; /* Maintain aspect ratio */
}

.section-one-column-left {
  display: flex;
  width: auto;

  justify-content: center;
  gap: 72px;
  margin: 24px 24px;
}

.feature-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 90%;
  max-width: 980px;
  border-radius: 12px;
  flex-shrink: 0;
  background: #f7f7f7;
  /* box-shadow: 0px 1.7px 10px 0px rgba(0, 0, 0, 0.12); */
  border: 2px solid #f7f7f7;
  border-radius: 8px;
}

.feature-container-two-features {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 90%;
  max-width: 980px;
  flex-shrink: 0;
}

.feature-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 200% */
  align-self: stretch;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.feature-text {
  color: #707880;
  /* website/body */
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.16px;
}

.feature-image img {
  width: 100%; /* Ensure the SVG takes the full width of its container */
  height: auto; /* Maintain aspect ratio */
}

.feature-container-2-column {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.callout {
  padding: 16px 16px 16px 12px;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  border-width: 1px;
  box-sizing: border-box;
  margin: 4px 0;
  gap: 6px;
  border: 1px solid #abd1f5;
  background-color: rgba(221, 235, 241, 0.3);
}

.callout-text {
  display: flex;
  flex-direction: column;
}

.callout-h4 {
  color: #000;
  font-weight: 600;
  margin: 0px;
}

.callout-p {
  margin: 10px 0px 0px 0px;
}

/* Testimonial stylings */

.header-stars {
  display: flex;
  align-items: center;
  background-color: #fef9f7;
  padding: 8px;
  border-radius: 3px;
  font-size: 16px;
  color: #e9ce45;
  border: 2px solid #fbe8dd;
  transform: rotate(-1.5deg);
  gap: 4px;
}

.header-stars-text {
  font-size: 24px;
  color: #f8792a;
  font-weight: 600;
}

.section-testimonials-container {
  display: block;
  columns: 4 20rem;
  gap: 1rem;
  padding: 20px;
}

.testimonial-container {
  background-color: #f7f7f7;
  border-radius: 8px;
  border: 2px solid #f7f7f7;
  width: calc(100% - 40px);
  padding: 20px;
  height: 100%;
  margin-top: 14px;
  break-inside: avoid;
}

.testimonial-heading-container {
  display: flex;
  gap: 6px;
  align-items: center;
}

.testimonial-author-container {
  display: flex;
  flex-direction: column;
  gap: 1px;
  line-height: 100%;
}

.testimonial-author-container-title {
  font-weight: 500;
  color: #000;
}

.testimonial-author-container-second-title {
  font-weight: 500;
  color: #878787;
  font-size: 14px;
}

.testimonial-author-photo {
  width: 50px;
  border-radius: 100%;
}

.testimonal-stars {
  margin-top: 10px;
  font-size: 18px;
  color: #e9ce45;
}

.testimonial-text {
  margin-top: 10px;
}

.testimonial-author-container-date {
  margin-top: 10px;
  font-weight: 500;
  color: #878787;
  font-size: 14px;
}

.testimonial-author-container

/* Placement of the header submenu */

.product-header-submenu {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 2px;
  top: 45px;
  left: 130px;
  z-index: 2;
  background-color: #fff;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0px 4px 18px rgb(0 0 0/4%),
    0px 2.025px 7.84688px rgba(0, 0, 0, 0.027), 0px 0.8px 2.925px rgb(0 0 0/2%),
    0px 0.175px 1.04062px rgba(0, 0, 0, 0.013);
}

.product-header-submenu-page-container {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 5px;
  padding: 8px 8px;
}

.product-header-submenu-page-container:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}

.product-header-submenu-icon-container {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 3px;
}

.product-header-title-subtitle-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  line-height: 100%;
}

.product-header-title {
  font-weight: 500;
}

.product-header-subtitle {
  color: #878787;
  font-size: 14px;
}

/* Customer logo containers */

.customer-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  color: #000;
  width: 90%;
  max-width: 980px;
  font-weight: 600;
}

.customer-logo-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  text-align: center;
  justify-content: center;
}

.customer-logo-subcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 20px;
}

.customer-logo {
  max-width: 110px;
  height: auto;
  padding-left: 0px;
}

.vertical-links {
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 100%;
}

.vertical-links:hover {
  cursor: pointer;
  opacity: 0.8;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 4px;
  max-width: 980px;
  width: 90%;
  border-radius: 8px;
  flex-shrink: 0;
  background: none;
  font-weight: 600;
  font-size: 32px;
  line-height: 124%;
}

.title-container.article {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 4px;
  width: 90%;
  border-radius: 8px;
  flex-shrink: 0;
  background: #fff;
  font-weight: 600;
  font-size: 32px;
  line-height: 124%;
  max-width: 760px;
}

.title-secondary {
  font-weight: 400;
  font-size: 24px;
  line-height: 124%;
}

.title-secondary.gray {
  color: #878787;
}

.title-secondary.gray.small {
  color: #878787;
  font-size: 16px;
}

.go-back {
  color: #878787;
  font-size: 16px;
  padding: 0px;
  font-weight: 400;
  transition: color 0.2s ease-in-out;
}

.go-back:hover {
  color: rgb(26, 136, 248);
  cursor: pointer;
}

.hover-opacity {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.hover-opacity:hover {
  opacity: 0.6;
}

.section-two-column {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  gap: 72px;
  margin: 64px 64px;
}

.feature-container-expander {
  flex: 1 0 0;
}

.feature-container-small {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 48.5%;
  border-radius: 12px;
  background: #f7f7f7;
  /*box-shadow: 0px 1.7px 10px 0px rgba(0, 0, 0, 0.12);*/
  border: 2px solid #f7f7f7;
}

.feature-container-extra-small {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30.5%;
  border-radius: 12px;
  background: #fff;
}

.feature-text-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  flex: 1 0 0;
}

.feature-text-container.home {
  box-shadow: 0px 1.7px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 0px;
  background-color: #fff;
}

.feature-title.home {
  padding: 0px 12px;
}

.feature-text.home {
  padding: 0px 12px 12px 12px;
}

.feature-container.home {
  background-color: #fff;
  box-shadow: 0px 1.7px 10px 0px rgba(0, 0, 0, 0.12);
  border: none;
}

.feature-container-small.home {
  background-color: #fff;
  box-shadow: 0px 1.7px 10px 0px rgba(0, 0, 0, 0.12);
  border: none;
}

.feature-container-small.third {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  box-shadow: none;
}

.feature-title.third {
  color: #4f4f4f;
  font-weight: 500;
}

.feature-title.third:hover {
  opacity: 0.8;
  cursor: pointer;
}

.feature-container-small img {
  width: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.feature-container-extra-small img {
  width: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.feature-container img {
  width: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* Header styles */
header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #f7f7f7;
  z-index: 10;
  padding: 4px 12px 4px 0px;
}

header h1 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.header-spacer {
  height: 40px;
  flex: 1 0 0;
}

.header-video-frame {
  display: flex;
  flex-direction: column;
  background-color: #ffff;
  padding-top: 18px;
  margin: 20px;
  max-width: 944px;
  border-radius: 12px;
  border: 2px solid #e7e7e7;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.header-video-frame-circles-container {
  display: flex;
  padding-left: 16px;
  padding-bottom: 14px;
  flex-direction: row;
  gap: 8px;
  border-bottom: 1px solid #e7e7e7;
}

.header-video-frame-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e7e7e7;
}

/* Typography styles */
h2 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 32px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0px;
  margin-bottom: 10px;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  color: #878787;
  margin-bottom: 4px;
  margin-top: 12px;
}

.centered-header {
  position: relative;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 200px;
}

.centered-heading {
  position: relative;
  text-align: center;
  margin-top: 20px 0px;
}

.centered-heading svg {
  position: absolute;
  top: -50px; /* Adjust this value based on the desired position */
  left: 50%;
  transform: translateX(-50%);
  z-index: -1; /* Make the SVG appear behind the text */
}

a {
  color: #000;
  text-decoration: none;
  padding: 6px 10px;
}

a:hover,
a.selected {
  color: #000;
  cursor: pointer;
}

/* Breaks */
.long-break {
  margin-bottom: 200px;
}

.mid-break {
  margin-bottom: 100px;
}

.short-break {
  margin-bottom: 20px;
}

.value-prop-text {
  font-weight: 400;
  padding-top: 20px;
  line-height: 24px;
}

.quote-container {
  display: flex;
  width: 90%;
  max-width: 980px;
  justify-content: center;
  align-items: flex-start;
}

.quote-block {
  display: flex;
  padding: 20px;
  flex-direction: column;
  max-width: 800px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
}

.quote {
  color: #878787;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.quote-author-image {
  height: 40px;
  width: auto;
}

.quote-author-block {
  display: flex;
  align-items: center;
  gap: 20px;
}

.quote-author-name {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.quote-author-company {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Testimonials on header */
.testimonials-on-header-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.testimonials-on-header-image-container {
  display: flex;
  flex-direction: row;
}

.testimonials-on-header-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: -12px;
  border: 3px solid #fff;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 6%, 0.1),
    0 1px 2px hsla(0, 0%, 6%, 0.1);
}

.testimonials-on-header-stars {
  color: #ffd700;
  font-size: 14px;
}

.testimonials-on-header-text {
  margin: 0px;
  font-size: 14px;
  line-height: 120%;
}

/* Colors */

.blue {
  color: #57a4eb;
}

.red {
  color: #eb5757;
}

.orange {
  color: #f8792a;
}

.yellow {
  color: #ebc257;
}

.light-purple {
  color: #f8792a;
}

.light-gray {
  color: #bdbdbd;
}

/* FAQ styles */

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 90%;
  max-width: 980px;
}

.question-answer-container {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(55, 53, 47, 0.09);
}

.question-container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-right: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  cursor: pointer;
}

.answer-container {
  padding-bottom: 16px;
}

.question-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  cursor: pointer;
}

/* Section styles */
section {
  margin: 40px 160px 80px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 32px;
  grid-auto-flow: dense;
  align-items: center;
  flex-shrink: 0;
}

.section-first-wrapper {
  display: flex;
  justify-content: center; /* Center the section-first */
  margin-right: 20px; /* Add a 20px margin on both sides */
  margin-left: 20px; /* Add a 20px margin on both sides */
  margin-top: 80px;
  margin-bottom: 0px;
}

.section-first {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%; /* Make it adapt to the available width */
  width: auto; /* Allow it to grow or shrink */
}

.section-first-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.section-first-title {
  color: #141719;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 64px */
  width: 900px;
  margin: 0px 0px 0px 0px;
}

.section-first-text {
  display: inline;
  height: 68px;
  flex-direction: column;
  justify-content: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.16px;
  text-align: center;
  width: 750px;
  padding-bottom: 20px;
  height: 100%;
}

.section-first-text-bold {
  font-weight: 500;
}

.section-first-text-gray {
  color: #878787;
}

.section-first-text-gray.home {
  font-size: 24px;
}

.section-first-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

section h1 {
  color: #000;
  grid-column: 2 / span 10;
  text-align: center;
}

section.project svg {
  max-height: 640px;
}

section.project div.description {
  grid-column: 9 / span 4;
}

section.project div.picture {
  grid-column: 1 / span 8;
  text-align: center;
}

section.project-alt div.description {
  grid-column: 1 / span 4;
}

.step-text-container {
  width: 60%;
}

section.project-alt div.picture {
  grid-column: 5 / span 8;
  text-align: center;
}

.article-footer {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: 100%;
  background-color: #f7f7f7;
  padding: 20px;
  color: #000;
  gap: 0px;
  border: none;
}

.article-footer-author-name {
  font-weight: 500;
  margin-bottom: 16px;
}

/* Footer styles */
footer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  padding-top: 20px;
  color: #878787;
  gap: 20px;
  width: 90%;
  max-width: 980px;
}

/*
footer a {
  color: #878787;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  transition: background 0.2s ease-in-out;
  transition: border-bottom 0.1s ease-in;
}
  */

footer p {
  margin-top: 0px;
}

footer svg {
  margin-top: 0px;
}

.footer-block-group {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

/* Form styles */
input,
textarea {
  width: 100%;
  background-color: hsl(0, 0%, 100%);
  border: 2px solid #000;
  color: rgb(51, 51, 51);
  padding: 8px 16px;
}

label {
  display: block;
  margin: 16px 0;
}

textarea {
  height: 180px;
}

input:focus,
textarea:focus {
  border: 2px solid #666666;
}

/* Responsive styles */
@media (max-width: 1248px) {
  body {
    width: auto;
  }

  section {
    margin: 64px 24px;
  }

  .hero-description-and-example-container {
    flex-direction: column-reverse;
  }

  .hero-description-container {
    width: 60vw;
  }

  .hero-example-container {
    width: 60vw;
  }

  .hero-cta-and-reviews-container {
    align-items: center;
  }

  .title-secondary {
    font-size: 18px;
    line-height: 125%;
  }

  h2 {
    font-size: 24px;
  }

  .quote-container {
    align-items: left;
    justify-content: left;
  }

  .quote-block {
    padding-left: 0px;
    padding-right: 0px;
    gap: 0px;
    text-align: left;
    align-items: flex-start;
  }

  .quote {
    font-size: 24px;
    text-align: left;
  }

  .quote-author-block {
    text-align: left;
  }
}

@media (max-width: 920px) {
  section {
    gap: 24px;
    margin: 64px 24px;
  }

  .mls {
    height: 100px;
  }

  .section-first {
    gap: 24px;
    margin: 64px 0;
  }

  .section-first-title {
    width: auto;
  }

  a.article-title-on-blog {
    padding: 0px 0px 0px 0px;
  }

  .section-first-text {
    width: auto;
    line-height: 100%;
  }

  nav {
    margin-right: 80px;
  }

  header svg {
    max-width: 200px;
    max-height: 91.08px;
  }

  .long-break {
    margin-bottom: 140px;
  }

  .header-picture img {
    width: 100%; /* Ensure the SVG takes the full width of its container */
    height: auto; /* Maintain aspect ratio */
  }

  .customer-logo-group {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 980px) {
  section {
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin: 64px 24px;
  }

  .step-text-container {
    width: 90%;
  }

  .section-first-title {
    font-size: 50px;
  }

  .hero-header {
    font-size: 50px;
    width: 90vw;
  }

  .section-first-text-bold {
    font-size: 16px;
  }

  .section-first-text-gray {
    font-size: 16px;
  }

  section h1,
  section.project div.picture,
  section.project div.description,
  section.contacte div.description,
  section.contacte div.photo,
  p {
    grid-column: 1 / span 4;
    text-align: left;
  }

  .picture svg {
    width: 100%; /* Ensure the SVG takes the full width of its container */
    height: auto; /* Maintain aspect ratio */
  }

  a {
    margin: 0;
  }

  .nav-toggle {
    display: flex;
    cursor: pointer;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background-color: #fff;
  }

  .modal-product-tiers-container {
    flex-direction: column;
  }

  .nav-toggle:hover {
    background-color: #f7f7f7;
  }

  .centered-header {
    display: flex;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px; /* Adjust the margin as per your design */
  }

  .section-first-text-gray.home {
    font-size: 16px;
  }

  .long-break {
    margin-bottom: 120px;
  }

  .quote-block {
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
    align-items: flex-start;
  }

  .quote {
    font-size: 24px;
    text-align: left;
  }

  .quote-author-block {
    text-align: left;
  }

  .footer-block-group {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }

  footer {
    gap: 20px;
  }
}

@media (max-width: 780px) {
  .section-first-text.free-tool-value-propcontainer {
    display: flex;
    flex-direction: column;
  }

  .step-container {
    display: flex;
    flex-direction: column;
  }

  a.article-title-on-blog {
    padding: 0px 0px 0px 0px;
  }

  .feature-container-2-column.software-home-staging-virtual {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: flex-start;
  }
}

@media (max-width: 640px) {
  .new-hero-header {
    font-size: 26px;
  }

  .new-hero-paragraph {
    font-size: 16px;
  }

  .hero-images-container {
    flex-direction: column;
    align-items: center;
  }

  .hero-before-image {
    width: 80%;
    border-radius: 8px 8px 8px 8px;
    border: 3px solid #ddd;
    aspect-ratio: 1.6;
    height: auto;
  }

  .hero-after-image {
    width: 100%;
    aspect-ratio: 1.6;
    height: auto;
  }

  .image-arrow-container {
    width: 10%;
    padding-bottom: 0px;
  }

  .image-arrow {
    transform: rotate(90deg);
  }

  .hero-description-and-example-container {
    flex-direction: column-reverse;
  }

  .hero-description-container {
    width: 90vw;
  }

  .hero-example-container {
    width: 90vw;
  }

  footer {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    align-items: left;
  }
  .step-container {
    display: flex;
    flex-direction: column;
  }

  a.article-title-on-blog {
    padding: 0px 0px 0px 0px;
  }

  .mls {
    height: 60px;
  }

  .section-first-title {
    font-size: 32px;
  }

  .hero-header {
    font-size: 32px;
  }

  .section-first-text-bold {
    font-size: 16px;
    display: flex;
    text-align: center;
  }

  .section-first-text-bold.home {
    display: block;
  }

  .header-stars {
    font-size: 12px;
    gap: 0px;
  }
  .header-stars-text {
    font-size: 16px;
  }

  .section-first-text-gray {
    font-size: 16px;
    display: flex;
    text-align: center;
  }

  h5 {
    text-align: left;
    font-size: 12px;
  }

  h2 {
    text-align: left;
    font-size: 20px;
    margin-bottom: 0px;
  }

  .step-container {
    display: flex;
    flex-direction: column;
  }

  .value-prop-text {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
  }

  .customer-logo-subcontainer {
    display: flex;
    flex-direction: column;
  }

  .section-first-title {
    font-size: 26px;
  }

  .hero-header {
    font-size: 26px;
  }

  .section-first-text-bold {
    font-size: 16px;
  }

  .section-first-text-gray {
    font-size: 16px;
  }

  .section-first-text-gray {
    margin-top: 10px;
    justify-content: center;
  }

  .quote {
    font-size: 20px;
  }

  .title-container {
    font-size: 20px;
  }

  .title-secondary {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .header-stars {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: baseline;
  }

  section {
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin: 64px 24px;
  }

  .title-container {
    font-size: 20px;
  }

  .section-first-text-container {
    gap: 64px;
  }

  .customer-logo {
    max-width: 120px;
    height: auto;
  }

  a.article-title-on-blog {
    padding: 0px 0px 0px 0px;
  }

  .feature-container-2-column {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: flex-start;
  }

  .feature-container-small {
    width: 100%;
  }

  .feature-container-extra-small {
    width: 100%;
  }

  .section-first-title {
    font-size: 46px;
  }

  .hero-header {
    font-size: 46px;
  }

  .quote-container {
    padding: 20px 20px;
  }

  .button-small {
    font-size: 20px;
  }

  .button-small-header {
    font-size: 16px;
  }

  .button-small-secondary {
    font-size: 16px;
  }

  .button-small-language {
    font-size: 16px;
    width: 80px;
  }

  .button {
    font-size: 20px;
  }

  .button-end-of-page {
    font-size: 20px;
  }

  header svg {
    max-width: 80px;
    max-height: 70.08px;
  }

  header {
    font-size: 12px;
  }

  nav {
    display: flex;
  }

  .long-break {
    margin-bottom: 80px;
  }
}

@media (max-width: 420px) {
  .new-hero-reviews-container {
    flex-direction: column;
  }

  .section-first-title {
    font-size: 32px;
  }

  .hero-header {
    font-size: 32px;
  }

  .hero-example-buttons-container {
    flex-direction: column;
  }

  .mls {
    height: 60px;
  }

  .modal-product-plan-description {
    width: 305px;
  }

  a.article-title-on-blog {
    padding: 0px 0px 0px 0px;
  }

  .section-first-text-bold {
    font-size: 16px;
  }

  .section-first-text-gray {
    font-size: 16px;
  }

  .section-first-text-container {
    gap: 25px;
  }

  .title-secondary {
    font-size: 16px;
  }

  .feature-text {
    font-size: 16px;
  }
}
